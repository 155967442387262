import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

export default class ForceGraph extends BaseGraph {
    render() {
        const graph = this.state.graphData
        if(graph && graph.nodes) {
            graph.nodes.forEach(function (node) {
                node.itemStyle = null;
                node.label = {
                    show: node.value > 30
                };
            });
        }

        return (
            <ReactEcharts
            opts={{renderer: 'svg'}}
            style={{ height: '400px'}}
            loadingOption= {{ color : "#007bff", lineWidth: 3 }}
            showLoading={ this.state.isLoading }
            hideLoading={ !this.state.isLoading}
            option={{
                title: { text: this.props.title},                
                tooltip: {},
                animationDuration: 1500,
                animationEasingUpdate: 'quinticInOut',
                series : [
                    {
                        type: 'graph',
                        layout: 'force',
                        data: graph && graph.nodes,
                        links: graph && graph.links,
                        roam: true,
                        zoom : 2,
                        focusNodeAdjacency: true,
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1,
                            shadowBlur: 10,
                            shadowColor: 'rgba(115, 194, 250, 0.3)',
                            color : '#007bff'
                        },
                        label: {
                            position: 'right',
                            formatter: '{b}'
                        },
                        lineStyle: {
                            color: '#007bff',
                            curveness: 0.2
                        },
                        emphasis: {
                            lineStyle: {
                                width: 5
                            }
                        },
                        force: {
                            repulsion: 120
                        }
                    }
                ]
            }}
        />
        )
    }
}