import {useAuthTokenInterceptor} from 'axios-jwt';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const requestRefresh = (refresh) => {
    return new Promise((resolve, reject) => {
        axios.post('/token/refresh/', {
            refresh
        })
            .then(response => {
                resolve(response.data.access);
            }, reject);
    });
};

const checkUrl = (urlString) => {
    if(urlString && urlString.endsWith('/')) {
        return urlString
    } else {
        return urlString + '/'
    }
}

const APIClient =() => {
    let api;

    api = axios.create({
        baseURL : process.env.REACT_APP_API_DOMAIN
    })
    api.interceptors.request.use((config) => {
        localStorage.setItem('LAST_API_CALL', (new Date()).getTime())
        return config;
    })

    useAuthTokenInterceptor(api,{ requestRefresh })
    axiosRetry(api, { retries: 3 });
    return api
}

class SkuAnalyzerAPI {
    
    async fetchDashboardConfig(url) {
        try {
            const response = await APIClient().get(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async fetchfilterOptions(url) {
        try {
            const response = await APIClient().get(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async fetchGraphValues(url, filters, params) {
        if (!params) {
            params = {}
        }
        try {
            const response = await APIClient().get(checkUrl(url), {
                params: {
                    ...params,
                    filters: filters
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

}

export default SkuAnalyzerAPI;