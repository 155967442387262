import React from 'react';
import GoogleTrendsLineGraph from '../GraphComponents/googleTrendsLineGraph';
import SkuAnalAPyzerAPI from '../ApiClass/SkuAnalyzerAPI'

const apiClient = new SkuAnalAPyzerAPI();

class GoogleTrends extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            tableData : undefined
        }
    }

    componentDidMount() {
        apiClient.fetchGraphValues(this.props.requestURL).then(response => {
            this.setState({
                tableData : response.data.result
            })
        }).catch(error => {
            Promise.reject(error)
        })
    }

    renderProgressBarColumn(value) {
        const negativeValue = value < 0 ? Math.abs(value)+'%' : '0%';
        const positiveValue = value > 0 ? value+'%' : '0%';

        return  <div className="row">
            <div className="col-sm-5 pr-0">
                <div className="progress  justify-content-end rounded-0">
                    <div className="progress-bar" role="progressbar" style={{ width: negativeValue}}/>
                </div>
            </div>
            <div className="col-sm-5 pl-0">
                <div className="progress rounded-0">
                    <div className="progress-bar" role="progressbar" style={{ width: positiveValue}}/> 
                </div>
            </div>
            <div className="col-sm-1">{value+'%'}</div>
        </div>
    }

    renderLineGraph(category) {
        return <GoogleTrendsLineGraph requestURL={this.props.requestUrlLine} category={category}   />
    }

    renderLoading() {
        return <div className="d-flex justify-content-center text-primary" style={{minHeight : '550px', alignItems: 'center'}}>
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
        </div>
    }

    renderRows(rowData) {
        const rows = rowData.map(row => {
            return <tr key={row[0]}>
                <td> { row[0] } </td>
                <td className="p-0"> { this.renderLineGraph(row[0]) } </td>
            </tr>
        })
        return <tbody>
            { rows }
        </tbody>
    }

    renderTable(tableData) {
        return <>
            <h5 className="pb-2"><strong>{this.props.title}</strong></h5>
            <table className="table table-bordered table-striped">
                <thead>
                <tr>
                    <th>{ this.props.header1 }</th>
                    <th className="text-center">{ this.props.header2 }</th>
                </tr>
                </thead>
                    {this.renderRows(tableData)}
            </table>
        </>
    }

    render() {
        return (
            <>
                { this.state.tableData ? this.renderTable(this.state.tableData) : this.renderLoading()}
            </>
        )
    }
}


export default GoogleTrends;