import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

export default class BarSeriesGraph extends BaseGraph {
    
    setHexColor(index) {
        const defaultColorDef = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3']

        if(index > defaultColorDef.length) {
            return "#" + Math.floor(Math.random()*16777215).toString(16); 
        } else {
            return defaultColorDef[index]  
        }
    }


    render() {
       
        const height = this.props.height ? this.props.height : '350px';
        const dataWithColor = this.state.graphData && this.state.graphData.map( (data, index) => {
            return {
                data : data.data,
                type : data.type,
                name : data.name,
                itemStyle : { color : this.setHexColor(index)}
            }
        })

        const newGraphData = dataWithColor && dataWithColor.reduce((accumulator, currentValue) => {
            currentValue.data.reduce((i, dataRow) => {
            // ignore i
                const [k,v] = dataRow;  // assign the pair into k(key) and v(value)
                accumulator.push({
                    name: k,
                    value: v,
                    itemStyle: currentValue.itemStyle
                });
                return i;
            }, []); // end of inner reduce
             
            return accumulator;
        }, []);

        return (
            <ReactEcharts
                opts={{renderer: 'svg'}}
                style={{ height: height }}
                loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                showLoading={ this.state.isLoading }
                hideLoading={ !this.state.isLoading }
                option={{
                    title: { text: this.props.title},
                    xAxis: this.props.xAxisOptions,
                    yAxis: this.props.yAxisOptions,
                    tooltip: {},
                    legend: { },
                    series: [
                        {data: newGraphData, type: "bar", label : this.props.seriesLabelOptions}
                    ]
                }}
            />
        )
    }
}