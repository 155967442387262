import BaseGraph from "./BaseGraph";

export default class TableView extends BaseGraph { 
    renderLoading() {
        return <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    }

    renderTable() {
        const columnHeaders = Object.keys(this.state.graphData[0])
        const rows = Object.values(this.state.graphData).map(data => {
            return <tr  key={data[this.props.itemKey]} >
                {
                    columnHeaders.map(header=>  <td  key={header} className="text-left"><small>{ data[header] }</small></td> )
                }
            </tr>
        })
        
        return <div>
                <h5 className="px-3"><strong>{this.props.title}</strong></h5>
                <div className={"table-responsive " + this.props.additionalClassName}>
                    <table className="table table-sm" >
                        <thead>
                            <tr  style={{backgroundColor: '#f1f1f1'}}>
                                { columnHeaders.map(header => <th className="text-left" key={header}>{header}</th> ) }
                            </tr>
                        </thead>
                        <tbody>
                            { rows }
                        </tbody>
                    </table>
                </div>
        </div>
    }

    renderContent() {
        if(this.state.isLoading) {
            return this.renderLoading()
        } else {
            if(this.state.graphData && this.state.graphData.length > 0) {
                return this.renderTable()
            } else {
                return <div className="text-center">
                    <p>No data to display</p> 
                </div>
            }
        }
    }

    render() {
        return (
            <>
                {this.renderContent()}
            </>
        )
    }
}