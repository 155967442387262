import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

export default class NegativeBarGraph extends BaseGraph {

    render() {
        const data = this.state.isReverse ? this.state.graphData && this.state.graphData.map(row=>row).reverse() : this.state.graphData;
        const RightBarValues = data && data.map(row => row[1])
        const LeftBarValues = data && data.map(row => row[2])
        const dataYAxis = data && data.map(row => row[0])

        return (
                <ReactEcharts
                    opts={{renderer: 'svg'}}
                    style={{ height:'700px'}}
                    loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                    showLoading={ this.state.isLoading }
                    hideLoading={ !this.state.isLoading }
                    option={{
                        title: { text: this.props.title},
                        xAxis: this.props.xAxisOptions,
                        yAxis: {...this.props.yAxisOptions,
                                data : dataYAxis },                                            
                        tooltip: this.props.toolTipOptions,
                        toolbox : {
                            feature : {
                                myToolSort : {
                                    show: true,
                                    title : 'Sort',
                                    icon : 'path://M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z',
                                    onclick : this.handleReverseClick
                                }
                            }
                        },
                        series: [
                            {
                                type: 'bar',
                                stack: 'Total',
                                label: {
                                    show: true,
                                    position: 'right'
                                },
                                data: RightBarValues,
                                color: "#007bff"
                            },
                            {
                                type: 'bar',
                                stack: 'Total',
                                label: {
                                    show: true,
                                    position: 'left',
                                    formatter : function (params) {
                                        return Math.abs(params.data)
                                    } 
                                },
                                data: LeftBarValues
                            }
                        ]
                    }}
                />
        )
    }
}