import './mainContent.css'
import React from 'react'
import {Route} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {clearAuthTokens} from "axios-jwt";
import Header from './header';
import Filters from './filters';
// import SKUSales from '../Components/skuSales';
// import CrossSelling from '../Components/crossSelling';
// import SalesForecaster from '../Components/salesForecaster';
import SMDatalabsLogo from '../assets/smdatalabs.png';
import VisualizationPage from "../Components/VisualizationPage";
// import { DASHBOARD_CONFIG } from '../Constants/dashboardConfig';
import SkuAnalyzerAPI from '../ApiClass/SkuAnalyzerAPI';

const apiClient = new SkuAnalyzerAPI();

export default class Main extends React.PureComponent {
    
    constructor(props) {
        super(props)
        this.state = {
            filters : {},
            config : [],
            isLoading : true
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this)
    }
    
    componentDidMount() {
        apiClient.fetchDashboardConfig('/page/').then(response => {
            this.setState({
                config : response.data,
                isLoading : false
            })
        }).catch(error => {
            this.setState({
                isLoading : false,
                erroMsg : error.response && error.response.data && error.response.data.detail
            })
            Promise.reject(error)
        })
    }

    handleLogoutClick() {
        clearAuthTokens()
        this.props.history.replace('/');
    }

    setFilters(filterId, filters) {
        this.setState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [filterId]: JSON.parse(JSON.stringify(filters))
            }
        }))
    }

    render() {
        const filterPaths = this.state.config && this.state.config.reduce((accumulator, page) => {
            if (!accumulator.hasOwnProperty(page.filterId)) {
                accumulator[page.filterId] = [];
            }
            if(page.filterId !== null) {
                accumulator[page.filterId].push(page.path);
            }
            return accumulator
        }, {});
        
        // following is a hardcoded list of undefined filters for filterPaths
        // just because we need to show that the following paths should have a filter but not yet defined
        // expected behaviour is they will show "Not found" in the filters area
        filterPaths["undefined"] = [
            "/sku-analysis/sku-sales"
        ]
        
        const pathsWithFilters = Object.values(filterPaths).flat()
        const showSideFilters = pathsWithFilters.indexOf(this.props.history.location.pathname) >= 0;

        return (
            <>
                {
                    !this.state.isLoading && <Header onClick={this.handleLogoutClick} showSideFilters={showSideFilters} config={this.state.config}/>
                }

                <div className="container-fluid custom-height" data-testid="main-content-div">
                    {
                        this.state.isLoading ? <div className="d-flex justify-content-center" style={{paddingTop:'25%', paddingBottom: '20%'}}>
                            <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </div>  : 
                        <div className="row">
                            <div className={showSideFilters ? "col-custom-wrapper-10" : "col-custom-wrapper-12"}>
                                <div className="col-sm-12">
                                    {
                                        this.state.config && this.state.config.map(page => {
                                            return <Route exact key={page.title} path={page.path} render={(props)=> ( <VisualizationPage {...page} {...props} filters={this.state.filters[page.filterId]} /> ) } />
                                        })
                                    }
                                </div>
                            </div>
                            
                            {
                                showSideFilters &&
                                <div className="col-custom-wrapper-2 filter-content-container pr-2">
                                    <div className="col-sm-12 text-right text-white  py-1">
                                        <button className="btn btn-link btn-sm text-white" onClick={this.handleLogoutClick}> <small  className="pr-1">Logout</small><FontAwesomeIcon icon={faSignOutAlt} /> </button>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="text-white bg-black  px-3 pt-3 pb-1 rounded-custom-top"> 
                                            <FontAwesomeIcon icon={faFilter}/> <span className="pl-2"><strong>Filters</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="bg-white rounded-custom-bottom pt-1">
                                            {
                                                Object.keys(filterPaths).map(filterId => <Route
                                                    exact path={filterPaths[filterId]}
                                                    render={
                                                        props => (<Filters
                                                            {...props} onGenerateClick={this.setFilters.bind(this, filterId)}
                                                            urlFilterID={filterId} isSideFilter={true} isClearable={true}
                                                        />)  // end of filter
                                                    }
                                                />)
                                            }

                                        </div>
                                    </div>
                                </div> 
                            }
                        </div>
                    }
                    <footer className="footer">
                    <div className="row">
                        <div className="col-sm-12 py-4">
                            <img src={SMDatalabsLogo} alt={"sm datalabs logo"}   style={{ width: '10em', height: 'auto'}}/>
                        </div>
                    </div></footer>
                </div>
            </>
        )
    }
}




