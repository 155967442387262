import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

export default class TreeMap extends BaseGraph {

    getLevelOption() {
        return [
            {
                itemStyle: {
                    borderWidth: 0,
                    gapWidth: 5
                }
            },
            {
                itemStyle: {
                    gapWidth: 1
                }
            },
            {
                colorSaturation: [0.35, 0.5],
                itemStyle: {
                    gapWidth: 1,
                    borderColorSaturation: 0.6
                }
            }
        ];
    }

    render() {
        return (
            <ReactEcharts
                opts={{renderer: 'svg'}}
                style={{ height:'450px'}}
                loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                showLoading={ this.state.isLoading }
                hideLoading={ !this.state.isLoading }
                option={{
                    title: { text: this.props.title},
                    tooltip: {
                        formatter : function (params) {
                            const value = params.value;
                            const treePathInfo = params.treePathInfo;
                            const treePath = [];

                            for (let i = 1; i < treePathInfo.length; i++) {
                                treePath.push(treePathInfo[i].name);
                            }
                            return treePath.toString().replace(/,/g , '->') + ': ' + value
                        }
                    },
                    series: [{ 
                        name: 'All Malls',
                        type: 'treemap',
                        visibleMin: 300,
                        label: {
                            show: true,
                            formatter: '{b}'
                        },
                        itemStyle: {
                            borderColor: '#fff'
                        },
                        levels: this.getLevelOption(),
                        data: this.state.graphData
                    }]
                }}
            />
        )
    }
}