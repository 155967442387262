import { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import SkuAnalAPyzerAPI from '../ApiClass/SkuAnalyzerAPI'

const apiClient = new SkuAnalAPyzerAPI();

export default class GoogleTrendsLineGraph extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            lineGraphData : undefined
        }
    }

    componentDidMount() {
        apiClient.fetchGraphValues(this.props.requestURL, null, {category: this.props.category}).then(response => {
            this.setState({
                lineGraphData : response.data.result
            })
        }).catch(error => {
            Promise.reject(error)
        })
    }


    render() {
        return (
            <ReactEcharts
            opts={{renderer: 'svg'}}
            style={{ height:'70px', width: '100%'}}
            showLoading={ !this.state.lineGraphData }
            loadingOption= {{ color : "#007bff", lineWidth: 2 }}
            hideLoading={ this.state.lineGraphData && this.state.lineGraphData.length > 0}
            seri
            option={{
                xAxis: {
                    type: "time",
                    show: false
                },
                yAxis: {
                    type: "value",
                    show: false
                },                                            
                tooltip: {},
                series: [{ 
                    data: this.state.lineGraphData,
                    type: "line",
                    color: "#007bff",
                    symbolSize: "1"
                }]                
            }}
        />
        )
    }
}