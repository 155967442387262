import React from 'react';
import LineGraph from '../GraphComponents/lineGraph';
import BarGraph from '../GraphComponents/barGraph';
import ForceGraph from '../GraphComponents/forceGraph';
import PropTypes from "prop-types";
import SimpleTable from "../GraphComponents/simpleTable";
import PieGraph from "../GraphComponents/pieGraph";
import NegativeBarGraph from "../GraphComponents/negativeBarGraph";
import RadarGraph from "../GraphComponents/radarGraph"
import Competitor from "../Components/competitor";
import TreeMapGraph from "../GraphComponents/treeMapGraph"
import GoogleTrends from "../Components/googleTrends";
import TableView from "../GraphComponents/tableView";
import BarSeriesGraph from '../GraphComponents/barSeriesGraphs';
import BarDatasetGraph from '../GraphComponents/barDatasetGraph';
import {getFormatter} from "../utils/formatters";


function applyFormatters(obj) {
    function recurseArray(arr) {
        return Object.values(arr).map(value => {
            if (value && typeof value === 'object') {
                // value is a child of obj, run recursion function to it
                if (Array.isArray(value)) {
                    return recurseArray(value);
                } else {
                    return recurseObject(value)
                }
            } else {
                return value
            }
        });
    }

    function recurseObject(obj) {
        const result = {};

        for (const key in obj) {
            let value = obj[key];
            if(value !== undefined) {
                if (value && typeof value === 'object') {
                    // value is a child of obj, run recursion function to it
                    if (Array.isArray(value)) {
                        result[key] = recurseArray(value);
                    } else {
                        result[key] = recurseObject(value);
                    }
                } else {
                    // apply formatter. Otherwise, just return
                    if (typeof(value) === "string" && value.startsWith('formatter|')) {
                        result[key] = getFormatter(value.split('|')[1])
                    } else {
                        result[key] = value;
                    }
                }
            }
        }
        return result
    }

    return recurseObject(obj);
}


class Visualization extends React.PureComponent {
    getVisualizationClass() {

        switch (this.props.type) {
            case 'bar':
                return BarGraph
            case 'line':
                return LineGraph
            case 'force':
                return ForceGraph
            case 'table':
                return SimpleTable
            case 'pie' :
                return PieGraph
            case 'negative_bar' :
                return NegativeBarGraph
            case 'radar' :
                return RadarGraph
            case 'competitor_graph' :
                return Competitor
            case 'tree_map' :
                return TreeMapGraph
            case 'google_trends_table' :
                return GoogleTrends
            case 'table_view' :
                return TableView
            case 'bar_series' :
                return BarSeriesGraph
            case 'bar_dataset' :
                return BarDatasetGraph
            default:
                return LineGraph
        }
    }

    renderCardTitle() {
        if (this.props.cardTitle) {
            return <h3 className="px-2"><strong>{this.props.cardTitle}</strong></h3>
        }
    }
    render() {
        const VizClass = this.getVisualizationClass()

        const newProps = applyFormatters(JSON.parse(JSON.stringify(this.props)));


        return <div className={"pt-4 col-sm-" + this.props.width}>
            <div className="card">
                <div className="card-body">
                    {this.renderCardTitle()}
                    <VizClass {...newProps}/>
                </div>
            </div>
        </div>
    }
}

Visualization.propTypes = {
    width: PropTypes.number.isRequired,
    type : PropTypes.string.isRequired,
    requestURL: PropTypes.string.isRequired,
    filters: PropTypes.object,
    title: PropTypes.string,
    cardTitle: PropTypes.string
}


class VisualizationPage extends React.PureComponent {
    render() {
        return (
            <>

                <div className="row">
                    { this.props.visualizations.map( viz => {
                        return <Visualization
                            key={viz.request_url + viz.title}
                            requestURL={viz.request_url}
                            filters={this.props.filters}
                            {...viz}
                        />
                    })}
                </div>
            </>
        )
    }
}

VisualizationPage.propTypes = {
    visualizations: PropTypes.array.isRequired
}

export default VisualizationPage;
