import './graphComponent.css'
import BaseGraph from "./BaseGraph";
import {ConvertNumberToHumanReadableStr} from '../utils/convertNumberToHumanReadableStr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

export default class SimpleTable extends BaseGraph {

    constructor(props) {
        super(props) 
        this.setState({
            columnToSort : undefined,
            sortDirection : undefined
        })
    }
    renderLoading() {
        return <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    }


    getMaxVal(dataValues, colName) {
        return Math.max(...dataValues.map(row=> row[colName]))
    }

    sortColumn(headerCol) {
        let sortDirection = '';
        if(this.state.sortDirection === undefined || this.state.sortDirection === 'DEFAULT' || this.state.columnToSort !== headerCol) {
            sortDirection = 'ASC'
        } else if(this.state.sortDirection === 'ASC') {
            sortDirection = 'DESC'
        } else if(this.state.sortDirection === 'DESC') {
            sortDirection = 'DEFAULT'
        } 

        this.setState({
            columnToSort : headerCol,
            sortDirection : sortDirection
        })
    }

    renderHeaders(headers) {
        let arrowDirection = ""
        if(this.state.sortDirection === 'ASC') {
            arrowDirection = <FontAwesomeIcon icon={faSortUp} color="#007bff"/>
        } 

        if(this.state.sortDirection === 'DESC') {
            arrowDirection = <FontAwesomeIcon icon={faSortDown} color="#007bff"/>
        }

        return  headers.map(header => {
            const arrowDisplay = header === this.state.columnToSort ? arrowDirection : ''; 
            return <th key={header} className={arrowDisplay ? "d-flex text-left" : "text-left"} onClick={()=> this.sortColumn(header)}>{header}{arrowDisplay}</th>
        })
    }
    
    renderSpecialCell(value,max) {   
            const barSize = (value/max) * 100
            return <td className="text-right  px-1 py-0" style={{
                background: "linear-gradient(90deg, #8f959d "+barSize+"%, rgba(0,0,0,0) "+(barSize)+"%)"
            }}>
                <small>{ ConvertNumberToHumanReadableStr(value) }</small>
            </td>
    }

    renderRows(headers,maxPerNumericCols) {
        return Object.values(this.state.graphData).sort((a,b)=>{
            if (!this.state.columnToSort || this.state.sortDirection === "DEFAULT" || !this.state.sortDirection) {
                return 0
            }

            const valA = a[this.state.columnToSort];
            const valB = b[this.state.columnToSort];

            if (!isNaN(valA) && !isNaN(valB)) {
                // do your number comparison here
                if (this.state.sortDirection === 'ASC') {
                    return valA - valB;
                } else if (this.state.sortDirection === 'DESC') {
                    return valB - valA;
                } else {
                    return 0
                }
            } else {
                // test and experiment which direction and comparison gets the negative values
                const sortMultiplier = this.state.sortDirection === "DESC" ? -1 : 1;  // what this does is it reverses the comparison if it is using a different sort direction
                // actually you can apply this same principle on the number comparison
                if (valA > valB) {
                    return sortMultiplier * 1;  // example: if sort direction is desc, -1 * 1 = -1; if ascending 1 * 1 = 1
                } else if (valA < valB) {
                    return sortMultiplier * -1;  // example: if sort direction is desc, -1 * -1 = 1; if ascending 1 * -1 = -1
                } else {
                    return 0
                }
            }

        }).map(data=> {
            return <tr>
                {
                    headers.map(header=> {
                        if(maxPerNumericCols.hasOwnProperty(header)) {
                            const max = maxPerNumericCols[header]
                            
                            return this.renderSpecialCell(data[header],max)
                        } else {
                            return <td className="text-left px-1 py-0  format-text-cell"><small>{ data[header] }</small></td>
                        }
                    })
                }
            </tr>
        })
    }

    renderTable() {
        const colHeaders = Object.keys(this.state.graphData[0])
        const numericCols = colHeaders.filter(col=> this.state.graphData[0][col] && this.state.graphData[0][col] !== "" && !isNaN(this.state.graphData[0][col]))
        const maxPerNumericCols = numericCols.reduce((accumulator, columnName) =>{
            accumulator[columnName] = this.getMaxVal(this.state.graphData,columnName);
            return accumulator;
            
        }, {})
        
        return <div className="table-wrapper-scrollable bg-white" >
            <table className="table table-sm table-bordered table-striped" >
                <thead>
                    <tr>
                        { this.renderHeaders(colHeaders) }
                    </tr>
                </thead>
                <tbody>
                    { this.renderRows(colHeaders,maxPerNumericCols) }
                </tbody>
            </table>
        </div>
    }

    renderContent() {
        if(this.state.isLoading) {
            return this.renderLoading()
        } else {
            return this.renderTable()
        }
    }

    render() {
        return (
            <>
                { this.renderContent() }        
            </>
        )
    }
}