import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

const DEFAULT_COLOR = ['#61a0a8', '#2f4554', '#c23531' , '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3']

export default class BarDatasetGraph extends BaseGraph {
    render() {
        const height = this.props.height ? this.props.height : '350px';
        return (
            <ReactEcharts
                opts={{renderer: 'svg'}}
                style={{ height: height }}
                hideLoading={ !this.state.isLoading }
                showLoading={ this.state.isLoading }
                loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                option={{
                    title: { text: this.props.title},                                        
                    tooltip: {},
                    legend: {},
                    xAxis: this.props.xAxisOptions,
                    yAxis: this.props.yAxisOptions,    
                    dataset : this.state && this.state.graphData,
                    series : this.state && this.state.graphData && this.state.graphData.dimensions.slice(1).map((val,index)=> {
                        return  { 
                            type: 'bar',   
                            color : DEFAULT_COLOR[index]                         
                        }
                    }),
                    dataZoom: [
                        {
                            type: 'inside'
                        }
                    ]
                }}
            />
        )
    }
}