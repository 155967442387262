import './components.css'
import React from 'react';
import CompetitorGraphs from '../GraphComponents/competitorGraphs';
import { URL_FILTER_ID } from '../Constants/tableFilterConstants';

class Competitor extends React.PureComponent {


    render() {
        return (
            <>
            <nav>
                <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                    <a className="nav-link active" id="competitor-bulletin-tab" data-toggle="tab" href="#competitor-bulletin" role="tab" aria-controls="competitor-bulletin" aria-selected="true"><strong>Bulletin</strong></a>
                    <a className="nav-link" id="competitor-category-tab" data-toggle="tab" href="#competitor-category" role="tab" aria-controls="competitor-category" aria-selected="false"><strong>Category Specific</strong></a>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="competitor-bulletin" role="tabpanel" aria-labelledby="competitor-bulletin-tab">
                    <CompetitorGraphs pageTitle="Competitor Scan Bulletin" urlForTable1="/data/11/run/" urlForTable2="/data/12/run/" urlForGraph="/data/13/run/" 
                        titleForTable1="Top 10 Categories" titleForTable2="Bottom 10 Categories" titleForGraph="Category Trend" urlFilterID={ URL_FILTER_ID.COMPETITOR_CATEGORIES }
                        itemKey={"Category"} paramKey={"category"} 
                        textList={
                            <ul>
                                <li><strong><i>Increase/Decrease pertains to 3-Week Moving Average (3WMA) Interest Share Improvement during the week.</i></strong></li>
                                <li><strong><i>Interest Share pertains to estimated perceived interest percentage of consumers towards a certain category.</i></strong></li>
                                <li><strong><i>Only categories with significant Interest Share is considered for ranking (Approx. 90% of Overall Interest Share).</i></strong></li>
                            </ul>
                        }
                    />
                </div>
                <div className="tab-pane fade" id="competitor-category" role="tabpanel" aria-labelledby="competitor-category-tab">
                    <CompetitorGraphs pageTitle="Category Specific Trends" urlForTable1="/data/14/run/" urlForTable2="/data/15/run/" urlForGraph="/data/16/run/" 
                        titleForTable1="Top Trending Products" titleForTable2="Top Discounted Products" titleForGraph="Items Trend" urlFilterID={ URL_FILTER_ID.COMPETITOR_SPECIFIC }
                        itemKey={"Product"} paramKey={"name"}
                        textList={
                            <ul>
                                <li><strong><i>Trend Score pertains to 3-Week Moving Average (3WMA) Interest Share Improvement during the week.</i></strong></li>
                                <li><strong><i>Interest Share pertains to estimated perceived interest percentage of consumers towards a certain product <u>relative to its category.</u></i></strong></li>
                            </ul>}
                    />
                </div>
            </div>
            </>
        )
    }
}

export default Competitor;