import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

export default class BarGraph extends BaseGraph {
    render() {
        const height = this.props.height ? this.props.height : '350px';
        return (
            <ReactEcharts
                opts={{renderer: 'svg'}}
                style={{ height: height }}
                loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                showLoading={ this.state.isLoading }
                hideLoading={ !this.state.isLoading }
                option={{
                    title: { text: this.props.title},
                    xAxis: this.props.xAxisOptions,
                    yAxis: this.props.yAxisOptions,                                            
                    tooltip: {},
                    series: [{ 
                        data: this.state.graphData,
                        type: "bar",
                        color: "#007bff",
                        label : this.props.seriesLabelOptions
                    }],
                    dataZoom: [
                        {
                            type: 'inside'
                        }
                    ]
                }}
            />
        )
    }
}