import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import SkuAnalyzerAPI from '../ApiClass/SkuAnalyzerAPI';

const dateFormat = require("dateformat");


const CustomDateInputField = React.forwardRef((props,ref) => (
    <div className="input-with-icons">
        <i  onClick={props.onClick}><FontAwesomeIcon icon={faCalendarAlt} color="#007bff"/></i>
        <div className="form form-control custom-form-control" onClick={props.onClick}> {props.dateDisplay}</div>
    </div>
))

const apiClient = new SkuAnalyzerAPI();

class Filters extends React.PureComponent {
    constructor(props) {
        super(props) 
        this.state = {
            fieldsData : undefined,
            isLoading : true,
            filters : {},
            erroMsg: "",
            isFilterError: false
        }

        this.renderFilters = this.renderFilters.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleResetClick = this.handleResetClick.bind(this)
        this.handleGenerateClick = this.handleGenerateClick.bind(this)
        this.fetchFilterOptionsData = this.fetchFilterOptionsData.bind(this)
        this.handleTextboxChange = this.handleTextboxChange.bind(this)
        this.renderError = this.renderError.bind(this)
    }
    
    componentDidMount() {   
        this.fetchFilterOptionsData()        
    }


    getDefaultFilters(data) {
        const defaultFilters = data.filter(val=> val.default_value !== "").reduce((accumulator, fieldData) => {
            const defaultVal = fieldData.default_value;
            const field = fieldData.field
            accumulator[field] = defaultVal;
            return accumulator;
        }, {})

        return defaultFilters
    }

    setDefaultFiltersUI(data) {
        Object.keys(data).forEach(field => {
            this.setFilter(field, data[field])
        })
    }

    fetchFilterOptionsData() {
        apiClient.fetchGraphValues('/table/' + this.props.urlFilterID + '/filters/').then(response => {
            this.setState({
                    fieldsData : response.data,
                    isLoading: false,
                    isFilterError: false,
                    filters : {
                        ...this.getDefaultFilters(response.data)
                    }
            },()=>{
                (typeof this.props.onFieldsLoaded) === 'function' && this.props.onFieldsLoaded(response.data);
                this.setDefaultFiltersUI(this.getDefaultFilters(response.data))
                this.handleGenerateClick()
            })
        }).catch(error => {
            this.setState({
                isFilterError : true,
                isLoading : false,
                erroMsg : error.response && error.response.data && error.response.data.detail
            })
            Promise.reject(error)
        })
    }

    renderLoading() {
        return <div className="d-flex justify-content-center py-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    }

    setFilter(name, value) {
        this.setState(prevState => {
            return {
                ...prevState,
                filters : {
                    ...prevState.filters,
                    [name] : value
                },
            }
        })
    }

    handleDateChange(dates,name) {
        const [start, end] = dates;
        this.setState(prevState => {
            return {
                ...prevState,
                filters : {
                    ...prevState.filters,
                    [name] : [start,end]
                },
            }
        })
    }

    handleFilterChange(optionValue,optionName) {
        const fieldName = optionName.name
        const value = optionValue && optionValue.value
        const _this = this;
        _this.setState(prevState => {
            return {
                ...prevState,
                filters : {
                    ...prevState.filters,
                    [fieldName] : value
                }
            }
        },()=>{
            if(!_this.props.isSideFilter) {
                _this.handleGenerateClick()
            }
        })
    }

    handleTextboxChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                ...prevState,
                filters : {
                    ...prevState.filters,
                    [name] : value
                }
            }
        })
    }

    handleResetClick() {
        this.setState({
            filters : {
                ...this.getDefaultFilters(this.state.fieldsData)
            }
        },()=>{
            this.setDefaultFiltersUI(this.getDefaultFilters(this.state.fieldsData))
            this.handleGenerateClick()
        })
    }

    cleanFilters(filters) {
        // clone and remove object with null values so it will not be passed 
        // in the request with filters
        const clonedFilters = JSON.parse(JSON.stringify(filters));

        for(let propName in clonedFilters) {
            if(clonedFilters[propName] === null) {
                delete clonedFilters[propName]
            }
        }

        return clonedFilters
    }

    handleGenerateClick() {
        const filters = this.cleanFilters(this.state.filters)
        this.props.onGenerateClick(filters)
    }
    
    renderError() {
        return <div className="d-flex justify-content-center py-5">
            <h6>{this.state.erroMsg}</h6>
      </div>
    }

    renderFilters() {
        let fieldToShow;
        let dateDisplay = '';
        const filters = this.state.fieldsData.map( field => {
            switch (field.type) {
                case 'date_range_picker':
                    if(this.state.filters && this.state.filters[field.field] && this.state.filters[field.field].length > 0) {
                        const display = this.state.filters[field.field].map(date => {
                            if(date) {
                                return date.toLocaleString().split(',')[0];
                            } else {
                                return ''
                            }
                        })

                        if(this.state.filters[field.field].includes(null)) {
                            dateDisplay = display[0]
                        } else {
                            dateDisplay = display[0] + ' - ' + display[1];
                        }

                    }

                    fieldToShow = <DatePicker
                            selectsRange
                            startDate = { this.state.filters && this.state.filters[field.field] && this.state.filters[field.field].length > 0 && this.state.filters[field.field][0]}
                            endDate = { this.state.filters && this.state.filters[field.field] && this.state.filters[field.field].length > 0 && this.state.filters[field.field][1] }
                            onChange={ (date)=>this.handleDateChange(date, field.field) }
                            popperPlacement="bottom"
                            shouldCloseOnSelect={false}
                            customInput={ <CustomDateInputField dateDisplay={dateDisplay}/> }
                    />
                    break;
                
                case 'text' : 
                    fieldToShow = <input type="text" className="form form-control form-control-sm" name={field.field} value={ this.state.filters[field.field] } onChange={this.handleTextboxChange} />
                    break;

                default:
                    const options = field.values.map(val => {
                        if(!this.props.isSideFilter) {
                            try {
                                const tmpVal = new Date(val);
                                return {  'label' : "Week of " + dateFormat(tmpVal, "mmmm d, yyyy"), 'value' : val }
                            } catch (e) {
                                return {'label': val, 'value': val}
                            }

                        } else {
                            return {  'label' : val, 'value' : val }
                        }
                    })
                    fieldToShow = <Select classNamePrefix="react-select"
                        name={field.field} options={options} onChange={this.handleFilterChange}
                        value={options.filter(option => JSON.stringify(option.value) === JSON.stringify(this.state.filters[field.field]))}
                        optionLabel="label" isClearable={ this.props.isClearable }
                        isSearchable={true}  placeholder=""/>
                    break;
            }

            if(!this.props.isSideFilter) {
                return <div key={field.field} className="col-sm-2 py-3">{fieldToShow}</div>
            } else {
                return <div key={field.field} className="col-sm-12 py-1">
                    <div className="col-12 px-0"><small className="font-weight-bold">{ field.label }</small></div>
                    <div className="col-12 px-0">{fieldToShow}</div>
                </div>
            }
        })

        if(this.props.isSideFilter) {
            return <div>
                { filters }
                <div className="row pt-3 pb-5 mx-auto text-center">
                    <div className="col pr-2">
                        <button type="button" className="btn btn-dark btn-sm btn-block rounded-lg" onClick={this.handleResetClick}><small><strong>Reset</strong></small></button>
                    </div>
                    <div className="col pl-2">
                        <button type="button" className="btn btn-primary btn-sm btn-block rounded-lg"  onClick={this.handleGenerateClick}><small><strong>Generate</strong></small></button>
                    </div>
                </div> 
            </div>
        } else {
            return <div className="row">
                {filters}
            </div>
        }
        
    }

    renderContent() {
        if(this.state.isLoading) {
            return this.renderLoading()
        } else {
            if(this.state.isFilterError) {
                return this.renderError()
            } else {
                return this.renderFilters()
            }
        }
    }

    render() {
        return (
            <>
                { this.renderContent() }
            </>
        )
    }
}

export default Filters;