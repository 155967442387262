import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

const DEFAULT_COLOR = ['#61a0a8', '#2f4554', '#c23531' , '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3']
const DEFAULT_LEGEND_SYMBOL = ['circle', 'triangle', 'diamond', 'rect', 'roundRect','pin', 'arrow']

export default class LineGraph extends BaseGraph {
    render() {
      
        const height = this.props.height ? this.props.height : '350px';
        const legendOption = this.state.graphData && this.state.graphData.dimensions.slice(1).map((val,i)=> {
            return {
                name : val.toString(),
                icon : DEFAULT_LEGEND_SYMBOL[i]
            }
        })
        
        return (
            <ReactEcharts
                opts={{renderer: 'svg'}}
                style={{ height: height }}
                showLoading={ this.state.isLoading }
                loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                hideLoading={ !this.state.isLoading}
                option={{
                    title: { text: this.props.title},   
                    xAxis: this.props.xAxisOptions,
                    yAxis: this.props.yAxisOptions,                                     
                    tooltip: this.props.tooltipOptions,
                    legend: {
                        data : legendOption
                    } ,
                    dataset : this.state && this.state.graphData,
                    series : this.state && this.state.graphData && this.state.graphData.dimensions.slice(1).map((val,index)=> {
                        return  { 
                            name: val.toString(), 
                            type: 'line',
                            symbol : DEFAULT_LEGEND_SYMBOL[index] ,
                            symbolSize: 8                               
                        }
                    }),
                    dataZoom: [
                        {
                            type: 'inside'
                        }
                    ],
                    color: this.props.color ? this.props.color : DEFAULT_COLOR
            }}
            />
        )
    }
}