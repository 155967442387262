import React from 'react';
import { NavLink  } from 'react-router-dom';
import SKUAnalyzerLogo from '../assets/sku-analysis-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export default class Header extends React.PureComponent {

    renderTabs() {
        return this.props.config.map(val => {
            return <NavLink  key={val.title} to={ val.path } className="nav-link" activeClassName="active"><div className="pb-2 pt-1">{val.title}</div></NavLink>
        })
    }
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary pt-4 pb-0">
                <NavLink  to="/sku-analysis/competitor" className="navbar-brand mr-5">
                    <img src={SKUAnalyzerLogo} alt={"sm datalabs logo"}   style={{ width: '14em', height: 'auto'}}/>
                </NavLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <ul className="navbar-nav mr-auto"  id="nav-header">
                        { this.renderTabs() }
                    </ul>
                    
                    {!this.props.showSideFilters && 
                        <div style={{paddingBottom: '5.4em', paddingRight: '.5em'}}>
                            <button className="btn btn-link btn-sm text-white" onClick={this.props.onClick}> <small  className="pr-1">Logout</small><FontAwesomeIcon icon={faSignOutAlt} /> </button>
                        </div>
                    }
                </div>
            </nav>
        )
    }
}