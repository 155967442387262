import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";

export default class RadarGraph extends BaseGraph {
    render() {
        return (
            <ReactEcharts
                opts={{renderer: 'svg'}}
                style={{ height:'350px'}}
                showLoading={ this.state.isLoading }
                loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                hideLoading={ !this.state.isLoading}
                option={{
                    title: { text: this.props.title},                                       
                    tooltip: {},
                    radar : [],
                    series: [{ 
                        data: this.state.graphData,
                        type: "radar",
                        areaStyle: {}
                    }]
            }}
            />
        )
    }
}