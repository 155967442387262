import React from 'react';
import ReactEcharts from 'echarts-for-react';
import BaseGraph from "./BaseGraph";
import Filters from "../Main/filters"
import SkuAnalAPyzerAPI from '../ApiClass/SkuAnalyzerAPI'

const apiClient = new SkuAnalAPyzerAPI();



class CompetitorLineChart extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isLoading : true,
            graphData : [],
            additionItem : {}
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        return JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items) ? true : false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot) {
            this.fetchData()
        }
        
        if(prevProps.highlightedItem !== this.props.highlightedItem && !this.props.items.includes(this.props.highlightedItem)) {
            this.fetchAdditionalItem(this.props.highlightedItem)
        } 
    }

    fetchAdditionalItem(item) {
        
        this.setState({
            additionItem : {}
        })
        
        if(item) {
            apiClient.fetchGraphValues(this.props.requestURL, null, {[this.props.paramKey] : item} ).then(response => {
                this.setState({
                    additionItem :  {
                        data : response.data.result,
                        name : item
                    }
                })
            }).catch(error => {
                Promise.reject(error)
            })
        }
    }

    callApiClient(item) {
        apiClient.fetchGraphValues(this.props.requestURL, null, {[this.props.paramKey] : item} ).then(response => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    graphData : [
                        ...prevState.graphData,
                        {
                            data : response.data.result,
                            name : item
                        }
                    ],
                }
            })
        }).catch(error => {
            Promise.reject(error)
        })
    }

    fetchData() {
        this.setState({
            graphData : [],            
            additionItem : {}
        },()=>{
            if(this.props.items && this.props.items.length > 0) {
                for(let i = 0; i < this.props.items.length; i++) {
                    this.callApiClient(this.props.items[i])
                }
            }
        })
    }

    render() {
        const COLORS = [ "#007bff", "#ffde33", "#ff9933", "#cc0033", "#8040ff", "#660099", "#7e0023", "#7fe5f0", "#f7347a", "#008000", "#ff4040", "#9370db"]
        const newGraphData = [...this.state.graphData, this.state.additionItem]
        const clonedGraphData = [...this.state.graphData, this.state.additionItem]
        // const 
        const data = newGraphData.map((item,index) => {
            return {
                data : item.data && item.data.map(i =>  i[1] > 1000 ? [i[0],1000] : i),
                type : 'line',
                color : COLORS[index],
                smooth: true,
                name : item.name,
                lineStyle : {
                    width : this.props.highlightedItem === item.name ? 5 : 2,
                    shadowColor: this.props.highlightedItem === item.name ? 'rgba(0, 0, 0, 0.5)' : '',
                    shadowBlur: this.props.highlightedItem === item.name ? 10 : 0
                }
            }
        })
        
        return (
            <>
                <h3><strong>{this.props.title}</strong></h3>
                <ReactEcharts
                    opts={{renderer: 'svg'}}
                    style={{ height:'500px'}}
                    showLoading={ this.state.graphData.length < this.props.items.length }
                    loadingOption= {{ color : "#007bff", lineWidth: 3 }}
                    hideLoading={ !this.state.isLoading}
                    option={{
                        legend: {
                            data: newGraphData.map(item => item.name),
                            align : 'left'
                        },
                        grid : {
                            top: '25%'
                        },
                        xAxis: {
                            type: "time",
                        },
                        yAxis: {
                            type: "value"
                        },                                            
                        tooltip: {
                            formatter: function (params) {
                                const actualValueDisplay = clonedGraphData[params.seriesIndex].data.filter(timeStr => timeStr[0] === params.value[0])
                                const display = params.marker + ' ' + params.seriesName + '<br/><span class="ml-3"/>' +  Math.round(actualValueDisplay[0][1])+'%'
                                
                                return display
                            }                          
                        },
                        series: data,
                        dataZoom: [
                            {
                                type: 'inside'
                            }
                        ],
                    }}
                />
            </>
        )
    }
}

class CompetitorTable extends BaseGraph {

    
    renderLoading() {
        return <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    }

    renderTable() {
        if(this.state.graphData && this.state.graphData.length > 0) {
            const columnHeaders = Object.keys(this.state.graphData[0])
            const rows = Object.values(this.state.graphData).map(data => {
                return <tr  key={data[this.props.itemKey]} className={ this.props.highlightedItem === data[this.props.itemKey] ? 'table-active' : ''}>
                    {
                        columnHeaders.map(header=>  <td  key={header} className="text-left" onClick={()=>this.props.setHighlightedItem(data[this.props.itemKey])}><small>{ data[header] }</small></td> )
                    }
                </tr>
            })
            
            return <div>
                    <h5 className="px-3"><strong>{this.props.title}</strong></h5>
    
                    <table className="table table-sm" >
                        <thead>
                            <tr style={{backgroundColor: '#f1f1f1'}}>
                                { columnHeaders.map(header => <th className="text-left" key={header}>{header}</th> ) }
                            </tr>
                        </thead>
                        <tbody>
                            { rows }
                        </tbody>
                    </table>
            </div>
        } else {
            return <div>
                <h5 className="px-3"><strong>{this.props.title}</strong></h5>

                <table className="table table-sm" >
                    <thead>
                        <tr style={{backgroundColor: '#f1f1f1'}}>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>No Data</td>
                        </tr>
                    </tbody>
                </table>
        </div>
        }
        
    }

    renderContent() {
        if(this.state.isLoading) {
            return this.renderLoading()
        } else {
            return this.renderTable()
        }
    }

    render() {
        return (
            <>
                {this.renderContent()}
            </>
        )
    }
}

export default class CompetitorGraphs extends React.PureComponent {
    constructor(props) {
        super(props) 
        this.state={
            items : [],
            highlightedItem: null,
            filters: {}
        }

        this.filter = React.createRef()
    }    


    renderTableAndGraphs() {
        return <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card-deck">
                        <div className="card">
                            <div className="card-body px-0">
                                <CompetitorTable
                                    requestURL={this.props.urlForTable1}
                                    title={this.props.titleForTable1}
                                    filters={this.state.filters}
                                    onDataLoad={(data) => this.setState({items: [...data.map(row => row[this.props.itemKey])]})} // callback after data is onloaded to get values
                                    setHighlightedItem={(item) => this.setState(prevState => { return {highlightedItem : prevState.highlightedItem === item ? undefined : item}})}
                                    itemKey={this.props.itemKey}
                                    paramKey={this.props.paramKey}
                                    highlightedItem={this.state.highlightedItem}
                                />
                            </div>
                            {}
                        </div>
                        <div className="card">
                            <div className="card-body px-0">
                                <CompetitorTable
                                    requestURL={this.props.urlForTable2}
                                    title={this.props.titleForTable2}
                                    filters={this.state.filters}
                                    setHighlightedItem={(item) => this.setState(prevState => { return {highlightedItem : prevState.highlightedItem === item ? undefined : item}})}
                                    itemKey={this.props.itemKey}
                                    paramKey={this.props.paramKey}
                                    highlightedItem={this.state.highlightedItem}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-3">
                    { this.props.textList }
                </div>
            </div>

            <div className="row pt-3">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <CompetitorLineChart
                                requestURL={this.props.urlForGraph } title={this.props.titleForGraph} itemKey={this.props.itemKey} paramKey={this.props.paramKey}
                                items={this.state.items} highlightedItem={this.state.highlightedItem}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
    
    render() {
        return (
            <div className="col-12 bg-white py-2 rounded">
                <h3><strong>{this.props.pageTitle}</strong></h3>

                <Filters ref={this.filter}
                         urlFilterID={this.props.urlFilterID} isSideFilter={false}
                         onGenerateClick={filters => this.setState({filters: filters})}
                         isClearable={false}
                />

                {this.renderTableAndGraphs()}

            </div>
        )
    }
}