export const ConvertNumberToHumanReadableStr = (number) => {
    const SUFFIXES = {
        '12': 'T',
        '9' : 'B',
        '6' : 'M',
        '3' : 'K',
        '0' : ''
    }

    function getExponent(n) {
        if(n===0) {
            return 0;
        }

        return Math.floor(Math.log10(Math.abs(n)));
    }

    function precise(n) {
        return Number.parseFloat(n.toPrecision(3));
    }

    function toHumanString(sn) {
        var n = precise(Number.parseFloat(sn));
        var e = Math.max(Math.min(3 * Math.floor(getExponent(n) / 3), 24), -24);
        return precise(n / Math.pow(10, e)).toString() + SUFFIXES[e];
    }

    return toHumanString(number)
}