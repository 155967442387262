import {PureComponent} from 'react';
import SkuAnalyzerAPI from "../ApiClass/SkuAnalyzerAPI";
import PropTypes from 'prop-types'

const apiClient = new SkuAnalyzerAPI()

class BaseGraph extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            graphData : undefined,
            isLoading: true,
            isSorted : false
        }
        this.fetchData = this.fetchData.bind(this)
        this.handleReverseClick = this.handleReverseClick.bind(this)
    }

    handleReverseClick() {
        this.setState(prevState=> {
            return {
                isReverse : !prevState.isReverse
            }
        })
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // Compare previous value of filter props to current
        // If not equal set the flag to TRUE else FALSE, value will be used in componentDidUpdate
        return JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters) || this.state.isLoading;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot) {
            this.fetchData()
        }
    }

    fetchData() {
        this.setState({
            isLoading : true
        })

        const filters = this.props.filters && Object.keys(this.props.filters).length > 0 ? this.props.filters : undefined;
        apiClient.fetchGraphValues(this.props.requestURL, filters).then(response => {
            this.setState({
                graphData : response.data.result,
                isLoading : false
            },()=>{
                if (this.props.onDataLoad) {
                    this.props.onDataLoad(this.state.graphData)
                }
            })
        }).catch(error => {
            Promise.reject(error)
        })
    }
}

BaseGraph.propTypes = {
    filters: PropTypes.object,
    requestURL: PropTypes.string.isRequired,
    onDataLoad: PropTypes.func
}

export default BaseGraph